<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="filterDetail.keywords"
            placeholder="Arama Yap..."
            @keydown.enter="filterAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="filterAction"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col class="mt-1">
        <career-form-search-dates-card />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          variant="warning"
          class="ml-1"
          @click="resetFilter"
        >
          <FeatherIcon icon="TrashIcon" />
          Filtreyi Sıfırla
        </b-button>
        <b-button
          variant="danger"
          class="ml-1"
          :href="downloadURL"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Excel İndir
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import CareerFormSearchDatesCard from '@/views/Hr/CareerForm/Components/elements/searchDatesCard.vue'

export default {
  name: 'CareerFormSearchBar',
  components: {
    CareerFormSearchDatesCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
  },
  props: {
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: false,
    }
  },
  computed: {
    filterDetail() {
      return this.$store.getters['hrCareerForm/filterData']
    },
    dataLoading() {
      return this.$store.getters['hrCareerForm/dataLoading']
    },
    downloadURL() {
      const urlParams = []
      if (this.filterDetail.sdate) {
        urlParams.push(`sdate=${this.filterDetail.sdate}`)
      }
      if (this.filterDetail.edate) {
        urlParams.push(`edate=${this.filterDetail.edate}`)
      }
      if (this.filterDetail.keywords) {
        urlParams.push(`keywords=${this.filterDetail.keywords}`)
      }
      const params = urlParams.join('&')

      let url = `${this.$store.state.app.baseURL}/exports/hr/Careerform/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  methods: {
    resetFilter() {
      this.$store.dispatch('hrCareerForm/resetDataFilter')
    },
  },
}
</script>
