<template>
  <b-card no-body>
    <b-card-body>
      <career-form-search-bar :filter-action="searchData" />
    </b-card-body>
    <template v-if="dataLoading">
      <loading />
    </template>
    <template v-else>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        hover
      >
        <template #cell(adi)="data">
          {{ data.item.adi }} {{ data.item.soyadi }}
        </template>
        <template #cell(created)="data">
          {{ moment(data.item.created).format('llll') }}
        </template>
        <template #cell(id)="data">
          <b-dropdown
            variant="primary"
            text="Görüntüle"
            left
            split
            size="sm"
            @click="$router.push('/hr/career-form/view/' + data.item.id)"
          >
            <b-dropdown-item
              :href="downloadUrl + data.item.id"
              target="_blank"
            >
              <feather-icon icon="PrinterIcon" />
              <span class="align-middle ml-50">Yazdır</span>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="removeData(data.item.id)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Sil</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BCardFooter, BPagination, BDropdown, BDropdownItem, BDropdownDivider, BCardBody,
} from 'bootstrap-vue'
import CareerFormSearchBar from '@/views/Hr/CareerForm/Components/SearchBar.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Index',
  components: {
    Loading,
    CareerFormSearchBar,
    BCard,
    BTable,
    BCardFooter,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'adi',
          label: 'Adı Soyadı',
          thClass: 'align-middle',
        },
        {
          key: 'telefon',
          label: 'Telefon',
          thClass: 'align-middle',
        },
        {
          key: 'created',
          label: 'Tarih',
          thClass: 'align-middle',
        },
        {
          key: 'id',
          label: '',
          thClass: 'align-middle width-100',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_careerform.id AS id',
          'com_careerform.adi AS adi',
          'com_careerform.soyadi AS soyadi',
          'com_careerform.telefon AS telefon',
          'com_careerform.created AS created',
        ],
        where: {},
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/hr/careerform/pdf?token=${localStorage.getItem('downloadToken')}&id=`
    },
    dataList() {
      return this.$store.getters['hrCareerForm/dataList']
    },
    dataCount() {
      return this.$store.getters['hrCareerForm/dataCounts']
    },
    filterData() {
      return this.$store.getters['hrCareerForm/filterData']
    },
    dataLoading() {
      return this.$store.getters['hrCareerForm/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      const where = {}
      if (this.filterData.sdate) {
        where['date(com_careerform.created) >='] = this.filterData.sdate
      }
      if (this.filterData.edate) {
        where['date(com_careerform.created) <='] = this.filterData.edate
      }
      if (this.filterData.keywords) {
        const [name, surname] = this.filterData.keywords.split(' ')
        this.dataQuery.or_like = {
          'com_careerform.adi': name,
          'com_careerform.soyadi': surname,
          'com_careerform.eposta': this.filterData.keywords,
          'com_careerform.telefon': this.filterData.keywords,
          'com_careerform.ceptelefonu': this.filterData.keywords,
          'com_careerform.adres': this.filterData.keywords,
          'com_careerform.tckn': this.filterData.keywords,
        }
      } else {
        delete this.dataQuery.or_like
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('hrCareerForm/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$store.dispatch('hrCareerForm/deleteData', id)
    },
  },
}
</script>
